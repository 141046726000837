import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import { ImCross } from "@react-icons/all-files/im/ImCross";

interface Props {
  location: Location
}

const NotFoundPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const thank_you_img = data.thank_you_img?.childImageSharp?.fluid
  const green_dots = data.green_dots?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Meta site={meta} title="Page Not Found" />
        <BackgroundImage
            Tag="section"
            className="thank-you-bg"
            fluid={hero_background}
            alt="Thank You for Contacting Us"
        >

          <div id="thankyou-hero">
              <Container>
                <div className="col-lg-12 text-center">
                  <Img fluid={green_dots} className="thank-you-dots"/>
                    <div id="cross-container">
                      <ImCross className="cross-icon"/>
                    </div>
                    <h1 className="primary-text">
                        404: Page Not Found
                    </h1>
                    <p className="hero-text text-black">
                        The page that you have requested was not found
                    </p>
                    <Link to ="/">
                      <button className="hero-section-btn">Back to Home</button>
                    </Link>
                </div>
              </Container>
          </div>
        </BackgroundImage>
      <section id="faded-bg"></section>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "thank-you-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    thank_you_img: file(name: { eq: "thank-you-img" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    green_dots: file(name: { eq: "thank-you-green-dots" }) {
      childImageSharp {
        fluid(maxWidth: 115, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
